import React, { useContext } from 'react';
import HamburgerMenu         from 'react-hamburger-menu';

import { GlobalContext } from '@interness/web-core/src/providers';
import Link              from '@interness/web-core/src/components/elements/Link/Link';
import { useHeaderData } from '@interness/web-core/src/hooks';

import * as S from './styles';

const Header = () => {
  const data = useHeaderData();
  const { primaryMenuOpen, togglePrimaryMenu } = useContext(GlobalContext);
  const toggleMenu = () => togglePrimaryMenu(!primaryMenuOpen);
  return (
    <S.Header>
      <S.Container>
        <S.LogoSection>
          <Link to="/">
            {/*TODO*/}
            {/*<Logo logo={data.logo.localFile} title={data.seo_title} />*/}
            <img src={data.logo.localFile.publicURL} alt={data.seo_title} title={data.seo_title}/>
          </Link>
        </S.LogoSection>
        <S.CartSection id="int-cart"/>
        <S.MenuSection>
          <div aria-label="Menü">
            <HamburgerMenu isOpen={primaryMenuOpen} menuClicked={toggleMenu} strokeWidth={3} borderRadius={5}/>
            <p style={{ fontSize: '0.7rem', textAlign: 'center', marginTop: '5px', marginBottom: '0' }}>Menü</p>
          </div>
        </S.MenuSection>
      </S.Container>
    </S.Header>
  )
};

export default Header;