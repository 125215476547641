import styled            from '@emotion/styled';
import { readableColor } from 'polished';

export const Header = styled.header`
  z-index: 1101;
  position: fixed;
  width: 100%;
  background-color: ${props => props.theme.config.global.backgroundColor};
  @media all and (max-width: 960px) {
    position: absolute;
  }
`;

export const Container = styled.div`
  height: ${props => props.theme.config.components.header.height}px;
  max-width: ${props => props.theme.config.global.pageWidth};
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${props => props.theme.config.components.header.layout === 'default' ? 'space-around' : 'center'};
  > div {
    flex-basis: 0;
    margin: 0 20px;
  }
`;

export const LogoSection = styled.div`
  flex-shrink: 2 !important;
  flex-grow: ${props => props.theme.config.components.header.layout === 'default' ? '1' : '0 !important'};
  flex-basis: ${props => props.theme.config.components.header.layout === 'default' ? '0' : 'auto !important'};
  a {
    text-decoration: none;
    background-image: none;
    display: inline-flex;
    height: 100%;
    max-width: 300px;
    align-items: center;
    padding: 20px 0;
  }
  img {
    max-width: 100%;
    max-height: ${props => props.theme.config.components.header.height - 30}px;
    margin: 0;
  }
`;

export const MenuSection = styled.div`
  max-width: 40px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  div {
    cursor: pointer;
    > span {
      background: ${props => readableColor(props.theme.base_color, '#000', '#fff')} !important;
    }
  }
`;

export const CartSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  a {
    color: unset;
    display: block;
    height: 32px;
    svg {
      width: 32px;
      height: 32px;
    }
  }
`;

export const RolexWatchSection = styled.div`
  @media all and (max-width: 640px) {
    display: none;
  };
  justify-content: flex-end;
  display: flex;
  height: 100%;
  align-items: center;
`;