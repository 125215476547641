import styled from '@emotion/styled';
import { darken } from 'polished';

export const Sidebar = styled.div`
  height: 100%;
  background-color: ${props => props.theme.config.global.backgroundColor};
  > ul {
    position: relative;
    top: ${props => props.theme.config.components.header.height}px;
  }
  ul {
    list-style-type: none;
    margin: 0;
    background-color: ${props => props.theme.config.global.backgroundColor};
    li {
      margin: 0;
      padding: 0;
      .subnav {
        a {
          padding-left: 80px;
          padding-right: 20px;
        }
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 20px 20px 20px 40px;
        transition: background-color 0.2s;
        text-decoration: none;
        color: unset;
        &:hover, 
        &.current {
          color: ${props => props.theme.brand_color};
          background-color: ${props => darken(0.1, props.theme.config.global.backgroundColor)};
          font-weight: bold;
        }
      }
    }
  }
`;