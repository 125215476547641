import React, { useContext }              from 'react';
import { slide as Menu }                  from 'react-burger-menu'
import { useStaticQuery, graphql }        from 'gatsby';
import { GlobalContext, LanguageContext } from '@interness/web-core/src/providers';
import MenuStructure
                                          from '@interness/web-core/src/components/structure/MenuStructure/MenuStructure';

import * as S           from './style';
import { findT }        from '@interness/web-core/src/components/_helpers';
import NewMenuStructure from '@interness/web-core/src/components/structure/MenuStructure/NewMenuStructure';

const Sidebar = () => {
  const menuStyles = {
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      maxWidth: '350px',
    },
  };

  const menuOptions = {
    right: true,
    pageWrapId: 'page-wrap',
    outerContainerId: 'outer-container',
    customBurgerIcon: false,
    customCrossIcon: false,
    noOverlay: false,
    styles: { ...menuStyles },
    disableOverlayClick: () => toggleMenu(),
    disableCloseOnEsc: true,
  };
  const { primaryMenuOpen, togglePrimaryMenu } = useContext(GlobalContext);
  const toggleMenu = () => togglePrimaryMenu(!primaryMenuOpen);
  const { language } = useContext(LanguageContext);
  const data = useStaticQuery(query);
  const menu = data.directusMenus ? findT(data.directusMenus.translations, language).config : null;
  const menuNew = data.directusMenusNew ? data.directusMenusNew.config : null;
  return (
    <Menu isOpen={primaryMenuOpen} {...menuOptions}>
      <S.Sidebar>
        {menuNew
          ? <NewMenuStructure menu={menuNew} />
          : <MenuStructure menu={menu}/>
        }
      </S.Sidebar>
    </Menu>
  )
};

const query = graphql`
  query {
    directusMenus(position: {eq: "header"}) {
      ...Menus
    }
    directusMenusNew(position: {eq: "primary"}) {
      language
      position
      config {
        anchor
        display_name
        groups {
          display_name
          sub {
            anchor
            display_name
            path
          }
        }
        path
        external_path
        route
        sub {
          display_name
          path
          path_prefix
          external_path
        }
      }
    }
  }
`;

export default Sidebar;
