import React                       from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled                      from '@emotion/styled';
import BaseLayout                  from '@interness/web-core/src/components/Layout/Base';
import Spacer                      from '@interness/web-core/src/components/structure/Spacer/Spacer';
import CookieConsent               from '@interness/web-core/src/components/modules/CookieConsent/CookieConsent';
import GoogleRatingBox             from '@interness/web-core/src/components/GoogleRatingBox/GoogleRatingBox';

import Header          from '../Header/Header';
import Sidebar         from '../Sidebar/Sidebar';
import Footer          from '../Footer/Footer';
import { GlobalStyle } from './GlobalStyle';

const PageWrapper = styled.div`
  position: relative;
  width: 90%;
  max-width: ${props => props.theme.config.global.pageWidth};
  top: ${props => props.theme.config.components.header.height}px;
  background-color: ${props => props.theme.base_color};
  margin: auto;
`;

const DefaultLayout = ({ children, pageContext }) => {
  const { allSitePlugin, directusProject } = useStaticQuery(query);
  const showCookieConsent = allSitePlugin.nodes.filter(plugin => plugin.name === 'gatsby-plugin-matomo');
  return (
    <BaseLayout pageContext={pageContext}>
      <React.Fragment>
        <GlobalStyle/>
        {showCookieConsent.length >= 1 && <CookieConsent/>}
        {directusProject.google_place_id && <GoogleRatingBox placeId={directusProject.google_place_id}/>}
        <div id="outer-container">
          <Header/>
          <Sidebar/>
          <PageWrapper id="page-wrap">
            <main>
              {children}
            </main>
            <Spacer height={40}/>
          </PageWrapper>
          <Footer/>
        </div>
      </React.Fragment>
    </BaseLayout>
  )
};

const query = graphql`
    query {
        directusProject {
            google_place_id
        }
        allSitePlugin {
            nodes {
                name
            }
        }
    }
`;

export default DefaultLayout;
